import { useEffect, useRef } from "react";

export default function Actions({ children }) {
  // Keep track of actions being stuck and toggle attr
  const container = useRef();
  const observer = new IntersectionObserver(
    ([e]) => {
      e.target.setAttribute("stuck", e.intersectionRatio < 1);
    },
    {
      threshold: [1],
    },
  );

  useEffect(() => {
    observer.disconnect();
    if (container?.current) observer.observe(container.current);
  }, [container]);

  // Disconnect observer before destroy
  useEffect(() => () => observer.disconnect(), []);

  return (
    <div className="actions" ref={container}>
      <div className="actions__wrapper container">{children}</div>
    </div>
  );
}
