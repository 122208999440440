import { useEffect, useRef } from "react";

export default function Helper({ children }) {
  const container = useRef();
  const observer = new IntersectionObserver(
    ([e]) => e.target.setAttribute("stuck", e.intersectionRatio < 1),
    { threshold: [1] },
  );

  useEffect(() => {
    observer.disconnect();
    if (container?.current) observer.observe(container.current);
  }, [container]);

  // Disconnect observer before destroy
  useEffect(() => () => observer.disconnect(), []);

  return (
    <div className="helper" ref={container}>
      <div className="container">{children}</div>
    </div>
  );
}
