export default function Steps({ activeStep }) {
  const steps = [
    "Rijksregisternummer",
    "Selecteer",
    "Kies",
    "Besteed 1,4 milj.",
    // "Restmiddelen",
    "Blijf op de hoogte",
  ];

  return (
    <ul className="steps">
      {steps.map((step, i) => {
        let classes = ["step"];
        if (i < activeStep) classes.push("step--active");
        else if (i === activeStep) classes.push("step--current");
        return (
          <li className={classes.join(" ")} key={i}>
            <span className="step__counter">{i + 1}</span>
            {step}
          </li>
        );
      })}
    </ul>
  );
}
