import Actions from "../components/Actions";
import Slider from "../components/Slider";
import Helper from "../components/Helper";

export default function setBudgets({
  budgets,
  setBudgets,
  goToNextStep,
  goToPrevStep,
}) {
  const TOTAL_BUDGET = 1_400_000;
  const MIN_PER_THEME = 0;
  const MAX_PER_THEME = 600_000;
  const STEP = 10_000;
  const NUMBER_FMT = new Intl.NumberFormat("be-NL", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  const amountSpent = (b = budgets) => b.reduce((acc, cur) => acc + cur[1], 0);
  const done = () => amountSpent(budgets) === TOTAL_BUDGET;
  const availableBudget = () => NUMBER_FMT.format(TOTAL_BUDGET - amountSpent());

  function handleInput(index, amount) {
    if (MIN_PER_THEME > amount || amount > MAX_PER_THEME) return;

    let newBudgets = [...budgets];
    // We have to copy this object, otherwise we modify the object in the original array
    newBudgets[index] = [newBudgets[index][0], amount];
    // Go down until the amount spent is below the total budget
    while (amountSpent(newBudgets) > TOTAL_BUDGET) newBudgets[index][1] -= STEP;

    setBudgets(newBudgets);
  }

  return (
    <form onSubmit={goToNextStep} className="">
      <fieldset className="">
        <legend className="question container">
          Verdeel nu 1,4 miljoen euro over je gekozen thema&rsquo;s
          <p className="question__extra">
            De Burgerbegroting heeft jaarlijks 1.4 miljoen euro voor ideeën van
            bewoners.
            <br />
            Jij mag dat geld nu verdelen over jouw 8 thema&rsquo;s.
            <br />
            Je kan maximum 600.000 euro aan één thema geven.
          </p>
          {/* <p className="question__extra">
            Benieuwd naar de begroting van district Antwerpen? Die vind je{" "}
            <a href="" className="link" target="_blank" rel="noopener">
              hier
            </a>
            .
          </p> */}
        </legend>
        <Helper>
          {(() => {
            if (done()) {
              return (
                <span className="text--primary text--bold">
                  Je hebt het hele bedrag gebruikt. Klik op &lsquo;Bevestig mijn
                  keuze&rsquo;
                </span>
              );
            }

            return (
              <span className="">
                Je hebt nog{" "}
                <span className="text--primary text--bold">
                  {availableBudget()}
                </span>{" "}
                over
              </span>
            );
          })()}
        </Helper>

        <div className="themes container">
          {budgets.map((budget, index) => (
            <Slider
              budget={budget}
              key={index}
              handleInput={(amount) => handleInput(index, amount)}
            />
          ))}
        </div>
      </fieldset>
      <Actions>
        <button
          className="actions__button actions__button--prev button"
          onClick={goToPrevStep}
        >
          Vorige
        </button>
        <input
          className="actions__button button"
          type="submit"
          value="Bevestig mijn keuze"
          disabled={!done()}
        />
      </Actions>
    </form>
  );
}
