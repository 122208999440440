import Actions from "../components/Actions";
import Helper from "../components/Helper";
import Icon from "../components/Icon";
import { imgSrc } from "../lib/img";

export default function RefineThemes({
  refinedThemes,
  setRefinedThemes,
  selectedThemes,
  goToNextStep,
  goToPrevStep,
}) {
  const MAX_CHOICES = 8;

  function handleInput(e, theme) {
    const copy = [...refinedThemes];
    const currentIndex = copy.findIndex((t) => t._id === theme._id);
    if (currentIndex !== -1) copy.splice(currentIndex, 1);
    else if (!done()) copy.push(theme);
    else e.preventDefault();

    setRefinedThemes(copy);
  }

  const done = () => refinedThemes.length >= MAX_CHOICES;
  const helper = () => {
    const availableChoices = MAX_CHOICES - refinedThemes.length;
    switch (availableChoices) {
      case 0:
        return "Je hebt je acht thema's gekozen";
      case 1:
        return "Kies nog 1 thema";
      default:
        return `Kies er nu precies ${availableChoices}.`;
    }
  };

  return (
    <form>
      <fieldset className="">
        <legend className="question container">
          Deze thema’s vond je interessant. Wat zijn de acht belangrijkste?
        </legend>
        <Helper>{helper()}</Helper>

        <div className="themes container">
          {selectedThemes.map((theme, index) => (
            <ThemeCard
              theme={theme}
              onInput={handleInput}
              key={index}
              checked={refinedThemes.some((t) => t._id === theme._id)}
            />
          ))}
        </div>
      </fieldset>
      <Actions>
        <button
          className="actions__button actions__button--prev button"
          onClick={goToPrevStep}
        >
          Vorige
        </button>
        <input
          className="actions__button button"
          type="submit"
          value="Volgende"
          onClick={goToNextStep}
          disabled={!done()}
        />
      </Actions>
    </form>
  );
}

function ThemeCard({ theme, onInput, checked }) {
  const id = `theme-${theme._id}`;
  const iconName = () => (checked ? "check-circle" : "check-circle-outline");

  return (
    <div>
      <input
        type="checkbox"
        name="interest"
        id={id}
        hidden
        value={theme._id}
        checked={checked}
        onChange={(e) => onInput(e, theme)}
      />
      <label className="theme" htmlFor={id}>
        <div className="theme__info">
          <img src={imgSrc(theme)} alt="" className="theme__img" />
          <p className="theme__title">{theme.title}</p>
          <span className="theme__check">
            <Icon name={iconName()} addlClasses={"icon--lg"} />
          </span>
        </div>
      </label>
    </div>
  );
}
