import { useRef, useState } from "react";
import Actions from "../components/Actions";
import Helper from "../components/Helper";
import Icon from "../components/Icon";
import { imgSrc } from "../lib/img";

export default function SelectThemes({
  minimumSelection,
  selectedThemes,
  setSelectedThemes,
  goToNextStep,
  themes,
  phase,
  setPhase,
}) {
  function handleInput(theme) {
    const copy = [...selectedThemes];
    const currentIndex = copy.findIndex((t) => t._id === theme._id);
    if (currentIndex !== -1) copy.splice(currentIndex, 1);
    else copy.push(theme);

    setSelectedThemes(copy);
  }

  const form = useRef();

  function goToNextPhase(e) {
    e.preventDefault();
    form.current.scrollIntoView({ behaviour: "smooth" });
    setPhase(phase + 1);
  }

  function goToPrevPhase(e) {
    e.preventDefault();
    form.current.scrollIntoView({ behaviour: "smooth" });
    setPhase(phase - 1);
  }

  const phasedThemes = () => [...themes].splice(phase * 8, 8);
  const nrSelectedThemes = () =>
    themes.filter((t) => selectedThemes.includes(t)).length;
  const done = () => nrSelectedThemes() >= minimumSelection;
  // const helper = () => {
  //   if (nrSelectedThemes() === 0) {
  //     return `Kies ${
  //       phase === 2 ? "" : "nog "
  //     }minstens ${minimumSelection} thema's om verder te gaan`;
  //   } else if (nrSelectedThemes() < minimumSelection) {
  //     return `Kies nog minstens ${
  //       minimumSelection - nrSelectedThemes()
  //     } thema's om verder te gaan`;
  //   } else {
  //     return "Je hebt het minimum aantal thema's gekozen";
  //   }
  // };

  return (
    <form ref={form}>
      <fieldset className="">
        <legend className="question container">
          Welke thema’s vind je interessant?
          <ul className="list list--unordered">
            <li className="list__item">
              Er zijn{" "}
              <a
                href="https://burgerbegroting.be/24-thema-s"
                target="_blank"
                rel="noreferrer"
              >
                24 thema’s
              </a>{" "}
              waaruit je kan kiezen. Je krijgt ze per 8 te zien.
            </li>
            <li className="list__item">
              Duid in totaal minstens 8 thema’s aan die jij belangrijk vindt.
            </li>
            <li className="list__item">
              Je kan telkens naar de volgende 8 thema’s gaan of naar de vorige.
            </li>
            <li className="list__item">
              Op de laatste pagina zie je alle thema’s die jij interessant vond.
            </li>
            <li className="list__item">
              Kies op de laatste pagina precies 8 thema’s.
            </li>
            <li className="list__item">
              Klik op <Icon name="info-circle" addlClasses="theme__icon" /> om
              te lezen waar het thema over gaat.
            </li>
          </ul>
          {phase === 0 && (
            <p className="paragraph paragraph--attention">Klaar? Start!</p>
          )}
        </legend>
        <Helper>Kies de thema&apos;s die jij interessant vindt.</Helper>
        <div className="themes container ">
          {phasedThemes().map((theme, index) => (
            <ThemeCard
              theme={theme}
              onInput={handleInput}
              checked={selectedThemes.some((t) => t._id === theme._id)}
              key={index + phase * 8}
            />
          ))}
        </div>
      </fieldset>
      <Actions>
        {phase > 0 && (
          <button
            className="actions__button actions__button--prev button"
            onClick={goToPrevPhase}
          >
            Vorige
          </button>
        )}
        {phase < 2 && (
          <input
            className="actions__button button"
            type="submit"
            value="Toon volgende thema's"
            onClick={goToNextPhase}
          />
        )}
        {phase === 2 && (
          <input
            className="actions__button button"
            type="submit"
            value="Volgende"
            onClick={goToNextStep}
            disabled={!done()}
          />
        )}
      </Actions>
    </form>
  );
}

function ThemeCard({ theme, onInput, checked }) {
  const id = `theme-${theme._id}`;
  const [expanded, setExpanded] = useState(false);

  function toggleExpansion(e) {
    e.preventDefault();
    setExpanded(!expanded);
  }

  return (
    <div>
      <input
        type="checkbox"
        name="interest"
        id={id}
        hidden
        value={theme._id}
        checked={checked}
        onChange={() => onInput(theme)}
      />
      <label className="theme" htmlFor={id}>
        <div className="theme__info">
          <img src={imgSrc(theme)} alt="" className="theme__img" />
          <p className="theme__title">{theme.title}</p>
          <button className="theme__toggle" onClick={toggleExpansion}>
            <Icon name="info-circle" addlClasses="theme__icon" />
          </button>
        </div>
        {expanded && <p className="theme__description">{theme.description}</p>}
        <div className="theme__actions">
          <span className="theme__interest">Interessant</span>
        </div>
      </label>
    </div>
  );
}
