import { slugify } from "../lib/slug";
import Icon from "../components/Icon";
import { imgSrc } from "../lib/img";
import { useState } from "react";
import Actions from "../components/Actions";

export default function Result({ refinedThemes, budgets, response }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    response.email = data.get("email");
    try {
      await response.save();
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setError(
        "Er ging iets onverwacht mis en we konden je gegevens niet opslaan",
      );
    }
  };

  return (
    <div className="">
      <div className="container">
        <h1 className="title">Bedankt voor je deelname!</h1>
        <p className="paragraph">
          Wil je op de hoogte blijven van de activiteiten van de
          Burgerbegroting?
          <br />
          Schrijf je dan in voor onze nieuwsbrieven.
        </p>
        <p className="paragraph">
          We sturen je enkele keren per jaar info over elke nieuwe stap, en we
          laten je weten welke beslissingen er genomen zijn.
        </p>
        <form
          className="form form--inline"
          id="form"
          onSubmit={handleSubmit}
          disabled={loading}
        >
          <p className="paragraph">
            <label htmlFor="email" className="input__label">
              Vul hier je e-mailadres in.
            </label>
            <div className="input__wrapper">
              <div className="input__field-wrapper">
                <input
                  className="input__field"
                  type="email"
                  id="email"
                  name="email"
                  disabled={loading || success}
                />
                {loading && (
                  <Icon
                    name="circle-notch"
                    addlClasses="input__icon input__icon--loading"
                  />
                )}
                {success && (
                  <Icon name="check-circle-outline" addlClasses="input__icon" />
                )}
              </div>
              <input
                type="submit"
                value="Inschrijven"
                className="button"
                disabled={loading || success}
              />
            </div>
            {error.length > 0 && (
              <span className="input__error">
                <Icon
                  addlClasses="input__error-icon"
                  name="circle-exclamation"
                />{" "}
                {error}
              </span>
            )}
          </p>
        </form>
        <p className="paragraph">
          Als je niet inschreef voor onze nieuwsbrief, dan kan je de resultaten
          vanaf dinsdag 12 maart vinden op onze website{" "}
          <a
            href="https://burgerbegroting.be"
            target="_blank"
            className="link"
            rel="noreferrer noopener"
          >
            burgerbegroting.be
          </a>
          .
        </p>
        <h2 className="title title--secondary">Deel je keuzes</h2>
        <p className="paragraph">
          Jij hebt gekozen voor deze 8 thema&rsquo;s. Deel je keuze en nodig
          andere mensen uit om mee te doen!
        </p>
      </div>
      <div className="themes container">
        {refinedThemes.map((theme, index) => (
          <ThemeCard
            theme={theme}
            key={index}
            budget={budgets.find((b) => b[0] === theme.title)[1]}
          />
        ))}
      </div>
      <Actions>
        <a href="https://burgerbegroting.be" className="button">
          Ga terug naar burgerbegroting.be
        </a>
      </Actions>
    </div>
  );
}

function ThemeCard({ theme, budget }) {
  const shareURL = `${window.location.origin}/s/${slugify(theme.title)}`;
  const facebookURL = () =>
    `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`;
  const twitterURL = () =>
    `http://twitter.com/intent/tweet?text=${encodeURIComponent(shareURL)}`;

  async function shareOnFB(event) {
    const suspectedMobile = window.screen.width < 746;

    if (
      suspectedMobile &&
      navigator.canShare &&
      navigator.canShare({ url: shareURL })
    ) {
      event.preventDefault();
      await navigator.share({
        url: shareURL,
      });
    }
  }

  const formattedBudget = new Intl.NumberFormat("be-NL", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  }).format(budget);

  return (
    <div className="theme">
      <div className="theme__info">
        <img src={imgSrc(theme)} alt="" className="theme__img" />
        <p className="theme__title">
          {theme.title} - {formattedBudget}
        </p>
      </div>
      <div className="theme__actions">
        <span className="theme__cta">Deel dit thema</span>
        <span className="theme__share-wrapper">
          <a
            href={facebookURL()}
            onClick={shareOnFB}
            className="theme__share"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name={"facebook-square"} addlClasses="icon--xl" />
          </a>
          <a
            href={twitterURL()}
            className="theme__share"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name={"twitter-x-square"} addlClasses="icon--xl" />
          </a>
        </span>
      </div>
    </div>
  );
}
