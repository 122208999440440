export function enableFlexGapPolyfill() {
  // We only enable support for this polyfill if the user is using safari & doesn't support it yet.
  const isSafari = navigator.vendor === "Apple Computer, Inc.";
  if (isSafari) {
    const shouldPolyfill = !checkFlexGap();
    document.body.toggleAttribute("data-flex-gap-polyfill", shouldPolyfill);
  }
}

function checkFlexGap() {
  // create flex container with row-gap set
  const flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";

  // create two, elements inside it
  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex);

  return isSupported;
}
