import { useEffect, useRef, useState } from "react";
import Steps from "./components/Steps";
import RefineThemes from "./pages/RefineThemes";
import SelectThemes from "./pages/SelectThemes";
import SetBudgets from "./pages/SetBudgets";
import Result from "./pages/Result";
import Intro from "./pages/Intro";
import themes from "../_data/themes.json";

const ENABLE_SURVEY = false;

function randomSort(items) {
  const newItems = [...items];
  for (let i = newItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newItems[i], newItems[j]] = [newItems[j], newItems[i]];
  }
  return newItems;
}

const MINIMUM_THEMES_SELECT = 8;
const STEP_NAMES = [
  "intro",
  "select_themes",
  "refine_themes",
  "set_budgets",
  "email",
  "result",
];

// We want to sort the themes outside of the react component so this only happens once for each user session
const randomThemes = randomSort(themes);

export default function App({ response }) {
  const [activeStep, setActiveStep] = useState(0);
  const [selectThemesPhase, setSelectThemesPhase] = useState(0);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [refinedThemes, setRefinedThemes] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const main = useRef();

  useEffect(() => {
    if (!ENABLE_SURVEY) return;

    response.setAnswer(
      "selectedThemes",
      selectedThemes.map((v) => v.title),
    );
  }, [selectedThemes]);

  useEffect(() => {
    let newBudgets = [];
    for (const theme of refinedThemes) newBudgets.push([theme.title, 0]);

    // Reset budgets for all old themes
    if (ENABLE_SURVEY) {
      budgets.forEach((oldBudget) =>
        response.setAnswer(`budgets_${oldBudget[0]}`, 0),
      );
    }

    setBudgets(newBudgets);

    if (!ENABLE_SURVEY) return;
    response.setAnswer(
      "refinedThemes",
      refinedThemes.map((v) => v.title),
    );
  }, [refinedThemes]);

  useEffect(() => {
    if (!ENABLE_SURVEY) return;

    budgets.forEach((b) => response.setAnswer(`budgets_${b[0]}`, b[1]));
  }, [budgets]);

  useEffect(() => {
    // Reset refined themes when going back
    if (activeStep === 1) setRefinedThemes([]);

    // If exactly 8 themes were selected, we preselect them for the next screen
    if (activeStep === 2 && selectedThemes.length === MINIMUM_THEMES_SELECT) {
      setRefinedThemes(selectedThemes);
    }
  }, [activeStep]);

  function goToStep(step) {
    setActiveStep(step);
    if (ENABLE_SURVEY) {
      response.page = { name: STEP_NAMES[step], index: step };
      if (step >= 4) response.completed = true;
      response.save();
    }
    main.current.scrollIntoView({ behaviour: "smooth" });
  }

  return (
    <div className="" ref={main}>
      <Steps activeStep={activeStep} setActiveStep={setActiveStep} />
      <main className="">
        {activeStep === 0 && (
          <Intro
            goToNextStep={() => goToStep(1)}
            response={response}
            ENABLE_SURVEY={ENABLE_SURVEY}
          />
        )}
        {activeStep === 1 && (
          <SelectThemes
            minimumSelection={MINIMUM_THEMES_SELECT}
            themes={randomThemes}
            selectedThemes={selectedThemes}
            setSelectedThemes={setSelectedThemes}
            setPhase={setSelectThemesPhase}
            phase={selectThemesPhase}
            goToNextStep={() => goToStep(2)}
          />
        )}
        {activeStep === 2 && (
          <RefineThemes
            selectedThemes={selectedThemes}
            refinedThemes={refinedThemes}
            setRefinedThemes={setRefinedThemes}
            goToPrevStep={() => goToStep(1)}
            goToNextStep={() => goToStep(3)}
          />
        )}
        {activeStep === 3 && (
          <SetBudgets
            refinedThemes={refinedThemes}
            budgets={budgets}
            setBudgets={setBudgets}
            goToPrevStep={() =>
              goToStep(selectedThemes.length === MINIMUM_THEMES_SELECT ? 1 : 2)
            }
            goToNextStep={() => goToStep(4)}
          />
        )}
        {activeStep === 4 && (
          <Result
            refinedThemes={refinedThemes}
            response={response}
            budgets={budgets}
          />
        )}
      </main>
    </div>
  );
}
