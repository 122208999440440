import themes from "../../_data/themes.json";
import Icon from "../components/Icon";
import { imgSrc } from "../lib/img";

export default function Slider({ budget, handleInput, disabled }) {
  const STEP = 10_000;
  const MAX_PER_THEME = 600_000;
  const MIN_PER_THEME = 0;

  function up(e) {
    e.preventDefault();
    handleInput(budget[1] + STEP);
  }

  function down(e) {
    e.preventDefault();
    handleInput(budget[1] - STEP);
  }

  const theme = () => themes.find((t) => t.title === budget[0]);
  const formattedAmount = () =>
    new Intl.NumberFormat("be-NL", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 0,
    }).format(budget[1]);

  const valueAsPct = () => `${(budget[1] / MAX_PER_THEME) * 100}%`;

  const downDisabled = MIN_PER_THEME >= budget[1];
  const upDisabled = MAX_PER_THEME <= budget[1];

  return (
    <div className="slider">
      <label htmlFor={budget[0]} className="slider__label">
        <img src={imgSrc(theme())} alt="" className="slider__img" />
        <span className="slider__title">{theme().title}</span>
      </label>
      <div className="slider__actions">
        <button
          onClick={down}
          disabled={downDisabled}
          className="slider__button slider__button--down"
        >
          <Icon name="minus-circle" addlClasses={"icon--xl"} />
        </button>

        <div className="slider__wrapper">
          <output className="slider__amount">{formattedAmount()}</output>
          <input
            id={budget[0]}
            type="range"
            className="slider__input"
            step={STEP}
            min="0"
            max={MAX_PER_THEME}
            value={budget[1]}
            onChange={(e) => handleInput(+e.target.value)}
            disabled={disabled}
            style={{ "--slider-value": valueAsPct() }}
          />
        </div>
        <button
          onClick={up}
          disabled={upDisabled}
          className="slider__button slider__button--up"
        >
          <Icon name="plus-circle" addlClasses={"icon--xl"} />
        </button>
      </div>
    </div>
  );
}
