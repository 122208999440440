import { sha3_512 } from "js-sha3";
import { useState } from "react";
import { IMaskInput } from "react-imask";
import Actions from "../components/Actions";
import Icon from "../components/Icon";

export default function Intro({ goToNextStep, response, ENABLE_SURVEY }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(!ENABLE_SURVEY);
  const [error, setError] = useState("");
  const [rrn, setRrn] = useState("");

  function handleError(error) {
    Object.entries(error.details).forEach(([field, messages]) => {
      if (field === "validation_code") {
        messages.forEach((message) => {
          if (message === "is not allowed") {
            setError("Je rijkregisternummer is niet geldig om mee te stemmen");
          } else if (message === "already used") {
            setError("Je hebt al een keer gestemd voor de burgerbegroting");
          }
        });
      } else {
        setError(
          "Er ging iets onverwacht mis en we konden je inschrijving niet opslaan",
        );
      }
    });
  }

  async function handleInput(value) {
    setSuccess(false);
    setRrn(value);
    /* We don't do anything yet while the user is inputting */
    if (value.length !== 11) {
      setError("");
      return;
    }

    setLoading(true);
    const sha512 = sha3_512(value);
    response.validationCode = sha512;

    try {
      await response.save();
      setSuccess(true);
    } catch (error) {
      switch (error.name) {
        case "NotAuthorizedError":
          setError("Het is momenteel niet mogelijk om te stemmen.");
          break;
        case "UnprocessableEntityError":
          handleError(error);
          break;
        default:
          setError(
            "Er ging iets onverwacht mis en we konden je gegevens niet opslaan",
          );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="">
      <div className="container">
        <h1 className="title">Fijn dat je wil meedoen!</h1>
        {ENABLE_SURVEY && (
          <>
            <p className="paragraph">
              De Burgerbegroting is een project van district Antwerpen
              (postcodes 2000, 2018, 2020, 2030, 2050 en 2060).
            </p>
            <p className="paragraph">
              Om te checken of je een inwoner bent, en ouder dan 13 jaar, geef
              je je rijksregisternummer in. Je kan maar 1 keer stemmen.
            </p>
          </>
        )}
        {!ENABLE_SURVEY && (
          <p className="paragraph">
            De burgerbegroting 2024 fase 1 is afgelopen. Je kan wel nog geld
            verdelen, maar niet meer mee stemmen.
          </p>
        )}
      </div>

      <form className="">
        {ENABLE_SURVEY && (
          <div className="container input">
            <label htmlFor="rijksregisternummer" className="input__label">
              Vul je rijksregisternummer in
            </label>
            <div className="input__field-wrapper">
              <IMaskInput
                mask="00.00.00-000.00"
                value={rrn}
                unmask={true}
                onAccept={handleInput}
                placeholder="99.99.99-999.99"
                className="input__field"
              />
              {loading && (
                <Icon
                  name="circle-notch"
                  addlClasses="input__icon input__icon--loading"
                />
              )}
              {success && (
                <Icon name="check-circle-outline" addlClasses="input__icon" />
              )}
            </div>
            {error.length > 0 && (
              <span className="input__error">
                <Icon
                  addlClasses="input__error-icon"
                  name="circle-exclamation"
                />{" "}
                {error}
              </span>
            )}
          </div>
        )}

        {ENABLE_SURVEY && (
          <div className="container">
            <p className="paragraph">
              Ben je benieuwd naar de thema’s?
              <br />
              Je kan ze{" "}
              <a
                className="link"
                href="https://burgerbegroting.be/24-thema-s"
                target="_blank"
                rel="noreferrer"
              >
                hier
              </a>{" "}
              bekijken. Ook als je geen inwoner bent.
            </p>
          </div>
        )}
        <Actions>
          <input
            type="submit"
            value="Volgende"
            className="actions__button button"
            onClick={goToNextStep}
            disabled={!success}
          />
        </Actions>
      </form>
    </div>
  );
}
