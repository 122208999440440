import "./css/index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { enableFlexGapPolyfill } from "./lib/polyfill";
import { CollectClient } from "@tree-company/collect-js-client";

let responseCollectorURL = import.meta.env.VITE_COLLECT_URL;
if (import.meta.env.DEV && responseCollectorURL === undefined) {
  responseCollectorURL = "http://localhost:3000";
}

const client = new CollectClient(
  responseCollectorURL,
  import.meta.env.VITE_COLLECT_KEY,
);
const response = client.initTreesponse({ language: "nl" });

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App response={response} />
  </React.StrictMode>,
);

document.addEventListener("DOMContentLoaded", () => {
  enableFlexGapPolyfill();
  startShareController();
});

function startShareController() {
  const instances = document.querySelectorAll("[data-controller='share']");
  instances.forEach((el) => new ShareController(el));
}

class ShareController {
  constructor(el) {
    this.el = el;
    el.addEventListener("click", this.shareOnFB);
  }

  get url() {
    return this.el.getAttribute("data-share-url");
  }

  async shareOnFB(event) {
    if (navigator.canShare && navigator.canShare({ url: this.url })) {
      event.preventDefault();
      await navigator.share({
        url: this.url,
      });
    }
  }
}
